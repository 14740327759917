import {
  CATEGORY_FLAT_RENT,
  CATEGORY_FLAT_SALE,
  CATEGORY_HOUSE_RENT,
  CATEGORY_HOUSE_SALE,
  CATEGORY_LOCAL_RENT,
  CATEGORY_LOCAL_SALE,
  CATEGORY_OBJECT_RENT,
  CATEGORY_OBJECT_SALE,
  CATEGORY_PLOT_RENT,
  CATEGORY_PLOT_SALE,
  XXXL_NUM_INPUT_MAX_LENGTH,
} from 'consts';
import styled from 'styled-components';
import { getStoredToken } from './auth';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';

export const notEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  } else if (!!value && typeof value === 'object' && value.constructor === Object) {
    return Object.keys(value).length > 0;
  } else if (typeof value === 'string') {
    return value.length > 0;
  } else {
    return value != null;
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

export const isValidUrl = (string) => {
  // eslint-disable-next-line
  const res = string.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i);
  return res !== null;
};

export const buttonTooltipText = (type) => {
  switch (type) {
    case 'edit':
      return 'Edytuj dokument';
    case 'delete':
      return 'Usuń dokument';
    case 'cancel':
      return 'Anuluj dokument';
    case 'duplicate':
      return 'Wygeneruj duplikat';
    case 'download':
      return 'Pobierz dokument';
    default:
      return;
  }
};

export const InnerContainer = styled.div`
  @media (max-height: 600px) {
    position: absolute;
    overflow: auto;
    height: 100%;
    left: 0;
    right: 0;
    padding: 0 1.55rem 1.55rem;
  }
`;

export const validatePremisesNumber = (e) => {
  const regex = /^([0-9/\b]|,| )+$/;
  return e.target.value ? regex.test(e.target.value) && e.target.value.length <= XXXL_NUM_INPUT_MAX_LENGTH : true;
};
export const categoryToText = (category) => {
  switch (category) {
    case CATEGORY_HOUSE_SALE:
      return 'Dom na sprzedaż';
    case CATEGORY_HOUSE_RENT:
      return 'Dom na wynajem';
    case CATEGORY_PLOT_SALE:
      return 'Działka na sprzedaż';
    case CATEGORY_PLOT_RENT:
      return 'Działka pod dzierżawę';
    case CATEGORY_LOCAL_SALE:
      return 'Lokal na sprzedaż';
    case CATEGORY_LOCAL_RENT:
      return 'Lokal na wynajem';
    case CATEGORY_FLAT_SALE:
      return 'Mieszkanie na sprzedaż';
    case CATEGORY_FLAT_RENT:
      return 'Mieszkanie na wynajem';
    case CATEGORY_OBJECT_SALE:
      return 'Obiekt na sprzedaż';
    case CATEGORY_OBJECT_RENT:
      return 'Obiekt na wynajem';
    default:
      return;
  }
};

export const setBool = (d, items) => {
  items.forEach((i) => (d[i] = d[i] || false));
};
export const setNull = (d, items) => {
  items.forEach((i) => (d[i] = d[i] || null));
};

export const downloadFile = async (url, filenameFallback, errorHandle, fromApi = true) => {
  const serverUrl = fromApi ? ((window._env_ && window._env_.API_URL) || process.env.REACT_APP_API_URL) + '/' : '';
  await fetch(`${serverUrl}${url}`, {
    method: 'GET',
    headers: {
      authorization: 'Bearer ' + getStoredToken(),
      'Content-type': 'application/json; charset=utf-8',
    },
  }).then(async (response) => {
    const disposition = response.headers.get('content-disposition');
    let filename = /filename\*?=([^']*'')?([^;]*)/.exec(disposition)[2];

    if (filename) {
      filename = decodeURI(filename.replaceAll('"', ''));
    } else {
      filename = filenameFallback;
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = filename;
    a.click();
    a.remove();
  });
};

export const toastDefault = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const flexboxToPosMap = {
  'flex-start_flex-start': 'lu',
  'flex-start_center': 'cu',
  'flex-start_flex-end': 'ru',
  'center_flex-start': 'lm',
  center_center: 'mdl',
  'center_flex-end': 'rm',
  'flex-end_flex-start': 'ld',
  'flex-end_center': 'cd',
  'flex-end_flex-end': 'rd',
};

const defaultPhotoOverlayPosition = 'lu';

export const flexboxToPos = (pos) => {
  if (!pos) {
    return defaultPhotoOverlayPosition;
  }

  return flexboxToPosMap[pos] ?? defaultPhotoOverlayPosition;
};

export const posToFlexbox = (pos) => {
  const posToFlexboxMap = _.invert(flexboxToPosMap);

  if (!pos) {
    return posToFlexboxMap[defaultPhotoOverlayPosition];
  }

  return posToFlexboxMap[pos] ?? posToFlexboxMap[defaultPhotoOverlayPosition];
};

export const downloadFileWithLink = (href, name) => {
  fetch(href)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fixImageFilename(name);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const resize = (w, h, maxWidth, maxHeight) => {
  let width = w;
  let height = h;
  if (width > maxWidth) {
    height = (maxWidth * height) / width;
    width = maxWidth;
  } else if (height > maxHeight) {
    width = (maxHeight * width) / height;
    height = maxHeight;
  }
  return { width, height };
};

export const parsePaste = (text) => {
  let badCharacters = '';
  const fixedText = text
    .split('')
    .map((character, i) => {
      const ci = character.charCodeAt();
      if (ci === 10 || ci === 8211 || ci === 13 || (ci >= 32 && ci <= 380) || (ci >= 1040 && ci <= 1103))
        return character;
      badCharacters += text[i];
      return null;
    })
    .filter((i) => i !== null)
    .join('')
    .replaceAll('–', '-')
    .replaceAll('<p>', '')
    .replaceAll('</p>', '<br>');

  return { text: fixedText, badCharacters };
};

export const floorObject = (object, parent, separator = '.') => {
  return Object.keys(object).reduce((obj, item) => {
    const key = parent ? `${parent}${separator}${item}` : item;
    if (object[item] && typeof object[item] === 'object')
      obj = { ...obj, ...floorObject(object[item], key, separator) };
    else obj[key] = object[item];
    return obj;
  }, {});
};
const fixImageFilename = (name) => {
  const lowerCaseName = name.toLowerCase();
  if (lowerCaseName.endsWith('.jpg') || lowerCaseName.endsWith('.jpeg')) return name;
  return `${name}.jpg`;
};
export const downloadAllFiles = (files, zipName = 'download.zip') => {
  const zip = new JSZip();
  let count = 0;
  const zipFilename = zipName;
  files.forEach((i) => {
    JSZipUtils.getBinaryContent(i.original || i.src, (err, data) => {
      if (err) {
        throw err;
      }
      zip.file(fixImageFilename(i.name), data, { binary: true });
      count++;
      if (count === files.length) {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, zipFilename);
        });
      }
    });
  });
};

export const separateThousands = (number, separateBy = ' ') => {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separateBy) : '';
};

export const getHeaderSize = (panelWidth, type, isInvestment) => {
  let width =
    typeof panelWidth === 'number' ? panelWidth : (window.innerWidth - 30) * ([1, 2].includes(type) ? 0.66 : 0.5);
  if (type === 1) width = width / 2;
  if (isInvestment) {
    return { isSm: width < 565, isXs: width < 400, isXXs: width < 330 };
  }
  return { isSm: width < 488, isXs: width < 277 };
};

export const getNestedPropertyByString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1');
  s = s.replace(/^\./, '');
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const showTooltip = (target, validation, message) =>
  validation && (
    <UncontrolledTooltip
      className="tooltip-white tooltip-width-md"
      positionFixed
      hideArrow
      placement="right"
      target={target}
    >
      {message}
    </UncontrolledTooltip>
  );

export const getFixedGridSrc = (src) => {
  return src ? (src.endsWith('_orig.jpg') ? src.split('_orig.jpg')[0] + '_stamp.jpg' : src) : '';
};

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const validateEmail = (email) => {
  return Boolean(emailRegex.test(email.toLowerCase()));
};

export const checkAvifSupport = () => {
  var avif = new Image();
  avif.src =
    'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
  avif.onload = function () {
    document.body.classList.add('avif-supported');
  };
  avif.onerror = function () {
    document.body.classList.add('avif-not-supported');
  };
};

export const watermarkSizeLimit = (input) => {
  const { floatValue, value } = input;
  if (floatValue === 0 || value.includes('.')) return false;
  return !floatValue || (floatValue <= 100 && floatValue >= 1);
};

export const getAgentName = (agentData, agentId) => {
  // agentData: data from /v1/agents/settings

  if (!agentId) {
    return null;
  }

  const matchingIdAgents = agentData.data.agents.filter((currentAgentData) => currentAgentData.id === agentId);

  if (matchingIdAgents.length === 0) {
    return null;
  }

  let name = _.compact([matchingIdAgents[0].firstname, matchingIdAgents[0].lastname]).join(' ');

  if (!name) {
    return null;
  }

  return name;
};

export const getAgentWhithBranchName = (agentData, agentId) => {
  // agentData: data from /v1/agents/settings

  if (!agentId) {
    return null;
  }

  const matchingIdAgents = agentData.data.agents.filter((currentAgentData) => currentAgentData.id === agentId);

  if (matchingIdAgents.length === 0) {
    return null;
  }

  const matchingIdAgencies = agentData.data.agencies.filter(
    (currentAgencyData) => currentAgencyData.id === matchingIdAgents[0].agency_id,
  );

  if (matchingIdAgencies.length === 0) {
    return null;
  }

  let name = _.compact([
    matchingIdAgents[0].firstname,
    matchingIdAgents[0].lastname,
    '/ ' + matchingIdAgencies[0].name,
  ]).join(' ');

  if (!name) {
    return null;
  }

  return name;
};

export const removeObjectById = (array, idToRemove) => {
  return array.filter((element) => element.id !== idToRemove);
};

export const objectWithIdExists = (array, id) => {
  return array.filter((element) => element.id === id).length > 0;
};

export const coalesce = (...values) => {
  let result;

  _.forEach(values, (value) => {
    result = value;

    if (value) {
      return false;
    }
  });

  // first non-empty value
  // or the last value if all of them are empty
  return result;
};
